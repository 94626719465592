.chatView-wrap {
    text-align: center;
    min-height: calc(100vh - 145px);
}

.chatView-wrap .row {
    padding:10px;
    /*border-bottom: #DADADA solid;*/
}

.chatView-wrap .row.dark-row {
    /*border-bottom: none;*/
}

.chatView-wrap .row:nth-child(odd) {
    /*background-color: #f9f9ff;*/
}

.chatView-wrap .row:nth-child(even) {
    /*background-color: #f9f9ff;*/
}

.chatView-wrap .row.dark-row:nth-child(odd) {
    /*background-color: rgba(255,255,255,0.04);*/
}

.chatView-wrap .row.dark-row:nth-child(even) {
    /*background-color: rgba(255,255,255,0.09);*/
}


.chatView-wrap .row .row-content {
    text-align: left;
    max-width: 48rem;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 1.75;
}

.chatView-wrap .row .row-content .user-row{
    /*text-transform: uppercase;*/
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 2px;
}

.chatView-wrap .row .row-content .timestamp-row{
    font-size: 0.75rem;
    /*text-transform: none;*/
    color: #8f8f8f;
    margin-top: -6px;
    margin-left: 10px;
}

.chatView-wrap .row .row-content .content-row{
    /*background: #f2f2f2;*/
    /*padding: 3px 8px;*/
    border-radius: 5px;
    border:#8f8f8f;
}

.chatView-wrap .row.dark-row .row-content .content-row{
    /*background: #393939;*/
    color: #ccc
}

.chatView-wrap .row .row-content .content-row-plain{
}

.chatView-wrap .row.dark-row .row-content .content-row-plain{
    color: #ccc
}


.chatView-wrap .row.small-padding{
    padding:5px
}

.chatView-wrap .row .quick-reply-wrap {
    margin-top: 5px;
}

.chatView-wrap .row .quick-reply-button {
    margin-right: 5px;
    padding: 5px;
    border-radius: 5px;
    display: inline;
    font-size: 0.9rem;
    cursor:pointer;
    color:#8f8f8f;
    border:1px solid rgba(0,0,0,0.4);
}

.chatView-wrap .row.dark-row .quick-reply-button {
    color:#ccc;
    border:1px solid #ccc;
}

.chatView-wrap .row .quick-reply-button:hover {
    background: #f2f2f2;
    color:rgba(0, 0, 0, 0.87);
}

.chatView-wrap .row.dark-row .quick-reply-button:hover {
    background: rgba(255,255,255,0.1);
}

.row .system-status {
    color: #666666;
    /*color: #E1FA7C*/
    font-size:0.9rem;
}

.row .cursor {
    width: 3px;
    height: 10px;
    background-color: #666666;
    /*animation: blink 0.9s linear infinite;*/
    animation: blink 1.2s steps(2,end) infinite;
}

.row.dark-row .system-status {
    color: rgba(225,250,124,0.8);
}

.row.dark-row .cursor {
    background-color: rgba(225,250,124,1);
}

.row .system-status .system-line-arrow {
    color:#538DE4
}

.chatView-wrap .row .metadataImage {
    display:flex;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    background:transparent;
    cursor: pointer;
    overflow: clip;
    position: relative;
}

.chatView-wrap .row .metadataImage.small-container{
    width:200px;
    max-height:200px;
}

.chatView-wrap .row .metadataImage.full-container{
    /*width:100vw;*/
    /*height:100vh;*/
}

.chatView-wrap .row .metadataImage .image{
    width: unset;
    height: unset;
    max-width:100%;
    /*max-height:100%;*/
}

.chatView-wrap .row .metadataImage .bottom-shade{
    height: 15px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(0deg, rgba(42,42,42,1) 0%, rgba(255,255,255,0) 100%);
}



@keyframes blink {
    0% {
        opacity: 0;
    }
    /*50% {*/
    /*    opacity: 1;*/
    /*}*/
    100% {
        opacity: 1;
    }
}