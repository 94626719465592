.role-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding-top: 20px;
    max-width: 1400px;
    position: relative;
}

.role-content .box {
    border: 1px solid rgba(84,84,84,1);
    border-radius: 5px;
    margin:10px;
    min-width: 300px;
    max-width: 350px;
}

.role-wrap {

}

.role-wrap .row {
    padding: 0.5rem 0;
}
.role-wrap .row .section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.role-wrap .row .section.padding-top-section {
    padding-top: 10px;
}

.role-wrap .row .row-column {
    width: 50%;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    /*padding-top: 0.75rem*/
}
.role-wrap .row .row-column-full {
    width: 100%;
}

.role-wrap .button-simple {
    color:#cdced1;
    display: inline;
}

.role-wrap .button-simple_gray {
    color: #6a6e75;
}

.role-wrap .button-simple:hover {
    text-decoration: underline;
    cursor: pointer;
}

.role-wrap .button-simple_gray:hover {
    color: #cdced1;
    text-decoration: underline;
    cursor: pointer;
}

.role-wrap .row .placeholder {
    color: #6a6e75;
}

.role-wrap .row .role-title {
    font-size: 1.1rem;

}

.role-wrap .row .allocation {
    text-align: right;
}

.role-wrap .row .update_doc {
    text-align: right;
}

.role-wrap .row .specs {
    width: 100%;
    color:#cdced1
}

