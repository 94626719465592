.b {
    -webkit-animation: spin 27s linear infinite;
    animation: spin 27s linear infinite;
    transform-style: preserve-3d;
}

.b, .c {
    height: 40px;
    width: 40px;
}

.c {
    border: 1px solid #F06;
    border-radius: 99em;
    position: absolute;
}
.c:nth-child(1) {
    border-color: #F06;
    -webkit-animation: spin 27.2s linear 0.04s infinite;
    animation: spin 27.2s linear 0.04s infinite;
    opacity: 0.006;
}
.c:nth-child(2) {
    border-color: #ff0048;
    -webkit-animation: spin 27.2s linear 0.08s infinite;
    animation: spin 27.2s linear 0.08s infinite;
    opacity: 0.012;
}
.c:nth-child(3) {
    border-color: #ff002b;
    -webkit-animation: spin 27.2s linear 0.12s infinite;
    animation: spin 27.2s linear 0.12s infinite;
    opacity: 0.018;
}
.c:nth-child(4) {
    border-color: #ff000d;
    -webkit-animation: spin 27.2s linear 0.16s infinite;
    animation: spin 27.2s linear 0.16s infinite;
    opacity: 0.024;
}
.c:nth-child(5) {
    border-color: #ff1100;
    -webkit-animation: spin 27.2s linear 0.2s infinite;
    animation: spin 27.2s linear 0.2s infinite;
    opacity: 0.03;
}
.c:nth-child(6) {
    border-color: #ff2f00;
    -webkit-animation: spin 17.2s linear 0.24s infinite;
    animation: spin 17.2s linear 0.24s infinite;
    opacity: 0.036;
}
.c:nth-child(7) {
    border-color: #ff4d00;
    -webkit-animation: spin 17.2s linear 0.28s infinite;
    animation: spin 17.2s linear 0.28s infinite;
    opacity: 0.042;
}
.c:nth-child(8) {
    border-color: #ff6a00;
    -webkit-animation: spin 17.2s linear 0.32s infinite;
    animation: spin 17.2s linear 0.32s infinite;
    opacity: 0.048;
}
.c:nth-child(9) {
    border-color: #ff8800;
    -webkit-animation: spin 17.2s linear 0.36s infinite;
    animation: spin 17.2s linear 0.36s infinite;
    opacity: 0.054;
}
.c:nth-child(10) {
    border-color: #ffa600;
    -webkit-animation: spin 17.2s linear 0.4s infinite;
    animation: spin 17.2s linear 0.4s infinite;
    opacity: 0.06;
}
.c:nth-child(11) {
    border-color: #ffc400;
    -webkit-animation: spin 7.2s linear 0.44s infinite;
    animation: spin 7.2s linear 0.44s infinite;
    opacity: 0.066;
}
.c:nth-child(12) {
    border-color: #ffe100;
    -webkit-animation: spin 7.2s linear 0.48s infinite;
    animation: spin 7.2s linear 0.48s infinite;
    opacity: 0.072;
}
.c:nth-child(13) {
    border-color: yellow;
    -webkit-animation: spin 7.2s linear 0.52s infinite;
    animation: spin 7.2s linear 0.52s infinite;
    opacity: 0.078;
}
.c:nth-child(14) {
    border-color: #e1ff00;
    -webkit-animation: spin 7.2s linear 0.56s infinite;
    animation: spin 7.2s linear 0.56s infinite;
    opacity: 0.084;
}
.c:nth-child(15) {
    border-color: #c4ff00;
    -webkit-animation: spin 7.2s linear 0.6s infinite;
    animation: spin 7.2s linear 0.6s infinite;
    opacity: 0.09;
}
.c:nth-child(16) {
    border-color: #a6ff00;
    -webkit-animation: spin 7.2s linear 0.64s infinite;
    animation: spin 7.2s linear 0.64s infinite;
    opacity: 0.096;
}
.c:nth-child(17) {
    border-color: #88ff00;
    -webkit-animation: spin 7.2s linear 0.68s infinite;
    animation: spin 7.2s linear 0.68s infinite;
    opacity: 0.102;
}
.c:nth-child(18) {
    border-color: #6aff00;
    -webkit-animation: spin 7.2s linear 0.72s infinite;
    animation: spin 7.2s linear 0.72s infinite;
    opacity: 0.108;
}
.c:nth-child(19) {
    border-color: #4dff00;
    -webkit-animation: spin 7.2s linear 0.76s infinite;
    animation: spin 7.2s linear 0.76s infinite;
    opacity: 0.114;
}
.c:nth-child(20) {
    border-color: #2fff00;
    -webkit-animation: spin 7.2s linear 0.8s infinite;
    animation: spin 7.2s linear 0.8s infinite;
    opacity: 0.12;
}
.c:nth-child(21) {
    border-color: #11ff00;
    -webkit-animation: spin 7.2s linear 0.84s infinite;
    animation: spin 7.2s linear 0.84s infinite;
    opacity: 0.126;
}
.c:nth-child(22) {
    border-color: #00ff0d;
    -webkit-animation: spin 7.2s linear 0.88s infinite;
    animation: spin 7.2s linear 0.88s infinite;
    opacity: 0.132;
}
.c:nth-child(23) {
    border-color: #00ff2b;
    -webkit-animation: spin 7.2s linear 0.92s infinite;
    animation: spin 7.2s linear 0.92s infinite;
    opacity: 0.138;
}
.c:nth-child(24) {
    border-color: #00ff48;
    -webkit-animation: spin 7.2s linear 0.96s infinite;
    animation: spin 7.2s linear 0.96s infinite;
    opacity: 0.144;
}
.c:nth-child(25) {
    border-color: #00ff66;
    -webkit-animation: spin 7.2s linear 1s infinite;
    animation: spin 7.2s linear 1s infinite;
    opacity: 0.15;
}
.c:nth-child(26) {
    border-color: #00ff84;
    -webkit-animation: spin 7.2s linear 1.04s infinite;
    animation: spin 7.2s linear 1.04s infinite;
    opacity: 0.156;
}
.c:nth-child(27) {
    border-color: #00ffa2;
    -webkit-animation: spin 7.2s linear 1.08s infinite;
    animation: spin 7.2s linear 1.08s infinite;
    opacity: 0.162;
}
.c:nth-child(28) {
    border-color: #00ffbf;
    -webkit-animation: spin 7.2s linear 1.12s infinite;
    animation: spin 7.2s linear 1.12s infinite;
    opacity: 0.168;
}
.c:nth-child(29) {
    border-color: #00ffdd;
    -webkit-animation: spin 7.2s linear 1.16s infinite;
    animation: spin 7.2s linear 1.16s infinite;
    opacity: 0.174;
}
.c:nth-child(30) {
    border-color: #00fffb;
    -webkit-animation: spin 7.2s linear 1.2s infinite;
    animation: spin 7.2s linear 1.2s infinite;
    opacity: 0.18;
}
.c:nth-child(31) {
    border-color: #00e6ff;
    -webkit-animation: spin 7.2s linear 1.24s infinite;
    animation: spin 7.2s linear 1.24s infinite;
    opacity: 0.186;
}
.c:nth-child(32) {
    border-color: #00c8ff;
    -webkit-animation: spin 7.2s linear 1.28s infinite;
    animation: spin 7.2s linear 1.28s infinite;
    opacity: 0.192;
}
.c:nth-child(33) {
    border-color: #00aaff;
    -webkit-animation: spin 7.2s linear 1.32s infinite;
    animation: spin 7.2s linear 1.32s infinite;
    opacity: 0.198;
}
.c:nth-child(34) {
    border-color: #008cff;
    -webkit-animation: spin 7.2s linear 1.36s infinite;
    animation: spin 7.2s linear 1.36s infinite;
    opacity: 0.204;
}
.c:nth-child(35) {
    border-color: #006fff;
    -webkit-animation: spin 7.2s linear 1.4s infinite;
    animation: spin 7.2s linear 1.4s infinite;
    opacity: 0.21;
}
.c:nth-child(36) {
    border-color: #0051ff;
    -webkit-animation: spin 7.2s linear 1.44s infinite;
    animation: spin 7.2s linear 1.44s infinite;
    opacity: 0.216;
}
.c:nth-child(37) {
    border-color: #0033ff;
    -webkit-animation: spin 7.2s linear 1.48s infinite;
    animation: spin 7.2s linear 1.48s infinite;
    opacity: 0.222;
}
.c:nth-child(38) {
    border-color: #0015ff;
    -webkit-animation: spin 7.2s linear 1.52s infinite;
    animation: spin 7.2s linear 1.52s infinite;
    opacity: 0.228;
}
.c:nth-child(39) {
    border-color: #0900ff;
    -webkit-animation: spin 7.2s linear 1.56s infinite;
    animation: spin 7.2s linear 1.56s infinite;
    opacity: 0.234;
}
.c:nth-child(40) {
    border-color: #2600ff;
    -webkit-animation: spin 7.2s linear 1.6s infinite;
    animation: spin 7.2s linear 1.6s infinite;
    opacity: 0.24;
}
.c:nth-child(41) {
    border-color: #4400ff;
    -webkit-animation: spin 7.2s linear 1.64s infinite;
    animation: spin 7.2s linear 1.64s infinite;
    opacity: 0.246;
}
.c:nth-child(42) {
    border-color: #6200ff;
    -webkit-animation: spin 7.2s linear 1.68s infinite;
    animation: spin 7.2s linear 1.68s infinite;
    opacity: 0.252;
}
.c:nth-child(43) {
    border-color: #8000ff;
    -webkit-animation: spin 7.2s linear 1.72s infinite;
    animation: spin 7.2s linear 1.72s infinite;
    opacity: 0.258;
}
.c:nth-child(44) {
    border-color: #9d00ff;
    -webkit-animation: spin 7.2s linear 1.76s infinite;
    animation: spin 7.2s linear 1.76s infinite;
    opacity: 0.264;
}
.c:nth-child(45) {
    border-color: #bb00ff;
    -webkit-animation: spin 7.2s linear 1.8s infinite;
    animation: spin 7.2s linear 1.8s infinite;
    opacity: 0.27;
}

@-webkit-keyframes spin {
    from {
        transform: rotateY(360deg) rotateX(-360deg) rotateZ(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotateY(360deg) rotateX(-360deg) rotateZ(360deg);
    }
}