header {
    display: flex;
    flex-direction: column;
    background-color: transparent !important;
    position: sticky;
    top: 0px;
    left: 0px;
    right: 0px;
    -webkit-box-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 3;
    backdrop-filter: blur(5px);
    transition: border-bottom 150ms ease 0s, top 250ms ease 0s, background-color 300ms ease 0s;
}

header.isLight {
    background-color: rgba(20, 35, 40, 0.5);
}


header .contentContainer {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    flex-shrink: 0;
    height: 66px;
    max-width: 1200px;
    padding: 0 32px;
    width: 100%;
    margin: 0 auto;
}

.logo {
    width: 150px;
}

.desktop{
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.mobile {
    display: none;
}

.vertical_divider {
    padding: 0 10px;
}

.login_header_spacing{
    padding: 0 10px;
}

.navigation{
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: right;
    -ms-flex-pack: center;
    justify-content: right;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.content {
    padding: 0 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 600px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.content-box {
    max-width: unset;
}

a {
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: pointer;
    color: #D0D6E0;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

.navigation a {
    font-size: 14px;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #D0D6E0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    cursor: pointer;
    transition: color 0.2s;
}

a:hover {
    color: #ffffff;
}

.loginRow a {
    margin: 0px 10px 0px 0px
}

@media (max-width: 600px){
    header .contentContainer {
        padding: 0 10px 0 15px;
    }
    .logo {
        width: 100px;
    }

    .desktop, .loginRow{
        display: none;
    }
    .mobile {
        display: block;
    }

}
