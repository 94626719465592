.a_og, .a_og:hover {
    text-decoration: underline;
    color: inherit;
}

textarea:focus, input:focus{
    outline: none;
}

.dash-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
    max-width: 1400px;
    position: relative;
}

.dash-content .header {
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: 700;
}

.title-content {
    justify-content: left;
    /*padding-top: 20px;*/
    padding-left: 20px;
}


.dash-content .box {
    border: 1px solid rgba(84,84,84,1);
    border-radius: 5px;
    margin:10px;
    min-width: 350px;
    max-width: 350px;
}
.dash-content .full-box {
    min-width: unset;
    max-width: 1090px;
    width: 100%;
}

.dash-content.dash-content-left {
    justify-content: left;
}
.dash-content.dash-content-right {
    justify-content: right;
}

.dash-content .box-background-light:hover {
    background-color: #f9f9ff
}
.dash-content .box-background-dark:hover {
    border:1px solid #f2f2f2;
}

.box .header {
    height: 64px;
    padding-left: 20px;
}

.box .header h4 {
    margin-block-start:unset;
    line-height: 64px;
    color:#ffffff
}

.box .header .small-header {
    margin-block-start:unset;
    line-height: 64px;
    font-size: 12px;
    letter-spacing: 0.8px;
    color:#888;
    text-transform: uppercase;
    font-weight: 700;
}

.box .header .large-header {
    margin-block-start:unset;
    line-height: 64px;
    font-size: 16px;
    letter-spacing: 0.8px;
    color:#888;
    text-transform: uppercase;
    font-weight: 700;
}

.box .header.box-header-hover {
    background-color:rgba(185, 185, 185, 0.18);

}

.box .header .whitecolor {
    color:#f2f2f2;
}

.box .content,
.box .quest-content {
    /*min-height: 50px;*/
    padding: 20px;
}

.box .content.large-mid {
    font-size: 18px;
    justify-content: center;
}

.box .content .activateButtonWrap {
    min-height: 34px;
    margin: 0 auto;
}

.box .content .recentBoxRow {
    padding:10px 0
}

.box .box-button {
    background: rgba(0, 0, 0, 0.06);
    padding: .75rem;
    border-radius: .375rem;
    cursor: pointer;
}
.box .box-button.disabled {
    cursor: default;
}

.box .box-button:hover {
    background: rgba(0, 0, 0, 0.08);
}
.box .box-button.disabled:hover {
    background: rgba(0, 0, 0, 0.06);
}

.box .box-button-dark {
    background-color: hsla(0,0%,100%,.05);
    padding: .75rem;
    border-radius: .375rem;
    cursor: pointer;
}

.box .box-button-dark:hover {
    background-color: hsla(0,0%,100%,.15);
}

.box.box-background-light {
    /*background-color: #f9f9ff;*/
}

.color-gray {
    color:rgba(255, 255, 255, 0.7);
    background-color: rgba(255, 255, 255, 0.08);
}

.main-content-wrap {
    min-height: calc(100vh - 64px)
}

.chat-input-wrap {
    padding: 20px;
    width: 100%;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    display: flex;
}

.chat-input-wrap.with-border {
    border-top: 1px solid rgba(84,84,84,1);
}

.chat-input-wrap input {
    -webkit-appearance: none;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.06);
}

.chat-input-wrap input:hover {
    /*background-color: hsla(0,0%,100%,.85);*/
}

.chat-input-wrap .sendChatButton {
    margin-left: 10px;
}

/** dark color input */
.chat-input-wrap-dark {
    background-color: #1f2124;
}

.chat-input-wrap-dark input {
    -webkit-appearance: none;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    /*width: 100%;*/
    padding: 10px;
    background-color: hsla(0,0%,100%,.05);
    caret-color: #fff;
    color:#fff;
}

.chat-input-wrap-dark input:hover {
    /*background-color: hsla(0,0%,100%,.15);*/
}

.ask-question-result {
    margin:10px
}
.ask-question-result--response {
    padding:0px 10px 30px 10px;
}

.ask-question-result--view-context {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ask-question-result--view-context .context-icon.down {
    transform: rotate(90deg);
}

@media only screen and (max-width: 1030px) {
    .dash-content .box {
        width: 100%;
        max-width: unset;
        min-width: unset;
    }
}

