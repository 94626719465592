.heroContainer{

    max-width: 58rem;
    margin:0 auto;
    padding: 20px 32px 0 32px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.heroSubtitleText{
    color:rgb(150, 169, 180);
    font-size: 1.4rem;
    max-width: 768px;
    line-height: 1.3;
    padding-bottom: 20px;
}

.footerspace {
    height: 150px;
}

.button-red {
    background: #ff1300;
}
.button-red:hover {
    background: #f05b51;
}

.featurebox {
    display: flex;
    justify-content: center;
}

.textpop {
    color:#ffffff;
    /*background-color:rgb(6, 141, 153);*/
    padding: 10px 5px;
    margin:15px 5px;
    border-radius:10px;
    max-width:220px;
    min-width: 200px;
}

.quote_content {
    margin: 0 auto;
    padding: 50px 50px;
    max-width: 1000px;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}