.pagewrap {
    padding: 0 32px;
    margin: 0 auto 120px auto;
    max-width: 1200px;
}

.intext_link {
    text-decoration: underline;
}

.blog-header {
    background-image:url('../img/SPH_blog_head_background_dark.png');
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
}
.blog-header.background-light {
    background-image:url('../img/SPH_blog_head_background.png');
}

.bloglist-wrap {
    margin: 0 auto 120px auto
}

.pagetitle {
    display:flex;
    flex-direction: row;
    position: relative;
}
.pagetitle .sidespace {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 25%;
    min-width: 160px;
    margin-right: 24px;
}

.pagebody {
    font-size: 1.25rem;
    line-height: 2rem;
}

.blogarticle {
    margin-top: 32px;
    display:flex;
    flex-direction: row;
    position: relative;
}

.blogarticle .sidespace {
    -webkit-box-align: center;
    align-items: center;
    width: 25%;
    min-width: 160px;
    margin-right: 24px;
}
.blogarticle .blogtitle a {
    color:#D0D6E0
}
.blogarticle .blogtitle a:hover {
    text-decoration: underline;
}


.blogarticle .blogcontent a {
    text-decoration: underline;
    color:#d0d6e0
}

.contentwrap {
    padding: 0 10px;
}

@media (max-width: 700px) {
    .pagewrap{
        padding: 0px 10px;
        margin:0 auto;
    }
    .pagetitle, .blogarticle{
        -webkit-box-align: center;
        align-items: center;
        flex-direction: column;
    }
    .pagetitle .sidespace {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        width: 100%;
        margin-right: 0px;
    }
    .blogarticle .sidespace {
        width: 100%;
    }
}

.background-light a {
    color:#000 !important;
}
.background-light .blogarticle .blogtitle a {
    color:#000
}


@media (min-width: 1059px) {
    .pagewrap{
        padding-right: 180px;
    }
}
