h1 {
    font-size:3.0rem;
    font-weight:900;
    margin-bottom: 20px;
}

h2 {
    font-size:1.75rem;
    font-weight:500;
    margin-bottom: 15px;
}

h3 {
    font-size:1.85rem;
    font-weight:700;
    margin-bottom: 5px;
}

h4 {
    font-size: 1.5rem;
    line-height: 1.375;
    letter-spacing: -.01em;
    font-weight: 700;
}

.card-hover:hover {
    background: #f2f2f2 !important;
}

.card-hover.card-dark:hover {
    background: #555 !important;
}

.detailTitle {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.6em;
}

.activityTitle {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4375rem;
    margin-bottom: 5px;
}

.footer-container {
    border-top: 1px solid #292830;
    margin-top: 2rem;
}

.subtitle {
    color:#6a6e75
}

.subtitle_dark {
    color:#888
}

.footer-innerWrap {
    padding: 40px 24px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    min-height: 2rem;
    color: #8A8F98;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.footer-innerWrap .footer-logo {
}

.footer-innerWrap img {
    max-height: 30px;
}

img {
    max-width: 100%;
    height: auto;
}

.button {
    color: rgb(255, 255, 255);
    background: linear-gradient(92.88deg, rgb(6, 141, 153) 29.16%, rgb(9, 136, 154) 63.89%);
    cursor: pointer;
    border: none;
    flex-shrink: 0;
    font-weight: 500;
    text-shadow: rgb(0 0 0 / 25%) 0px 3px 8px;
    border-radius: 4px;
    font-size: 14px;
    height: 2rem;
    padding: 7px 0.8rem;

}

.button:hover {
    background: #3ab1c5;
}

.call-to-action-container {
    padding: 3rem 0;
}

.padding-top-20 {
    padding-top: 40px;
}

.text_color_light {
    color:#3c4858 !important;
}

.text_color_dark {
    color:#fff !important;
}

.maingrid {
    max-width: 74rem;
    display:grid;
}

.gridwrap {
    align-items: center;
}

.grid-img, .grid-list{
    max-width: 36rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: left;
}

.grid-img img {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    display: block;
    vertical-align: middle;
}

.dot-color-whiteblue {
    background: #f9f9ff;
}
.dot-color-purple{
    background:#9b5de5;
}
.dot-color-rose{
    background: #f15bb5;
}
.dot-color-yellow{
    background: #ffd60a;
}
.dot-color-light_blue{
    background: #00bbf9;
}
.dot-color-turquoise{
    background: #00f5d4;
}
.dot-color-pinky {
    background: #ee9c81;
}

.dot-color-cyan{
    background:rgb(6, 141, 153);
}
.dot-color-red{
    background:rgb(236, 72, 153);
}
.dot-color-blue{
    background:rgb(59,130,246);
}

.dot-color-blue_purple{
    background:#B2ABF2;
}
.dot-color-razzmic_berry{
    background:#9E5898;
}
.dot-color-claret{
    background:#89043D;
}
.dot-color-prussian_blue{
    background:#1C3041;
}
.dot-color-sea_green{
    background:#18F2B2;
}

.latest-research-content {
    max-width: 1120px;
    margin: 0 auto 40px auto;
    padding: 0 20px

}

.latest-research-title {

}

.research-title {
    font-size: 1.5rem;
    line-height: 1.375;
    letter-spacing: -.01em;
    font-weight: 700;
}

.research-subtitle {
    padding-left: 20px;
}

@media (min-width: 768px){
    .gridwrap {
        gap: 1.5rem;
        grid-template-columns: repeat(12,minmax(0,1fr));
        display: grid;
    }
    .grid-img{
        grid-column: span 6/span 6;
        width: 100%;
        max-width: none;
        direction: rtl;
        margin-bottom: 0;
    }
    .grid-list{
        grid-column: span 6/span 6;
        max-width: none;
        width: 100%;
    }
    .grid-hero{
        grid-column: span 6/span 6;
        max-width: none;
        width: 100%;
    }
    .grid-joblist{
        grid-column: span 8/span 8;
        max-width: none;
        width: 100%;
    }
    .textwrap {
        padding-left: 1rem;
    }

}

@media (min-width: 1024px){
    .grid-img{
        grid-column: span 6/span 6;
    }
    .grid-list{
        grid-column: span 6/span 6;
    }
    .grid-hero{
        grid-column: span 6/span 6;
    }
    .grid-joblist{
        grid-column: span 8/span 8;
    }

    .textwrap {
        padding-left: 3rem;
    }

}

.footer-column {
    padding-left: 30px;
}

@media (min-width: 1280px){
    .textwrap {
        padding-left: 4rem;
    }
}

