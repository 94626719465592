.card {
    margin-bottom: 20px;
    padding: 10px;
}

.sidebar-title {
    font-weight: 600;
    font-size: 1.4em;
    padding-bottom: 10px;
}

.jobcard {
    padding: 10px;
}

.job-container {
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;

    max-width: 1200px;
    min-height: 800px;
}

.columns {
    /*display: flex;*/
}

.column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0.75rem
}

.columns .is-narrow {
    flex: none;
    width: unset;
}

.sidebar {
}

.opportunityItem{
    padding: 1.5rem 1.25rem;
    border-bottom: 1px solid #efefef;
}

.opportunityItem:hover .opportunity_title {
    text-decoration: underline;
}

.opportunity_title{
    font-size: 22px;
    font-weight: bold;
}
.opportunity_title:hover{
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.2s;
}

a.opportunity_title {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    transition: color 0.2s;
}

.roletag {
    margin: 0px 5px 10px 0;
    display: inline-block;
    font-weight: 500;
}

.skilltag {
    background-color: #f2f2f2;
    color: #6a6a6a;
    border-radius: 0.25em;
    padding: 0.15em 1em;
    font-size: 1em;
    font-weight: 700;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0px;
}

.small_date{
    padding-top: 10px;
    font-size: 0.75rem;
}

@media screen and (min-width: 840px) {
    .columns {
        display:flex
    }
    .sidebar {
        width: 340px;
    }
}