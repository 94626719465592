.react-flow .react-flow__handle {
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background-color: rgba(31,33,36);
}

.react-flow .react-flow__handle-top {
    /*top: -10px;*/
}

.react-flow .react-flow__handle-bottom {
    /*bottom: -10px;*/
}

.react-flow .react-flow__node {
    /*height: 40px;*/
    /*width: 150px;*/
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 2px;
    /*font-weight: 700;*/
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
    stroke-width: 2;
}

.wrapper {
    flex-grow: 1;
    height: 100%;
}