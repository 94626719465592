.blackline {
    min-height: 200px;
    width: 100%;
    background: #1f2124;
}

.whiteline {
    min-height: 200px;
    width: 100%;
    background: #f9f9ff;
    padding: 20px 0;
    border-radius: 15px;
    border: 1px solid rgba(84,84,84,1);
}

.whiteline.dark {
    background: rgba(255, 255, 255, 0.08);
}

.reference_content{
    width: 70%;
    margin: 0 auto;
}

.subtitleHeader {
    color: #888;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.5em;
}

.quote {
    font-style: italic;
    max-width: 100%;
    margin: 0 auto;
    padding: 4px 0;
}

.references .row {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
}

.references .row img {
    width: 100%;
    vertical-align: middle;
    border-style: none;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

.col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    text-align: center;
}

@media (min-width: 992px){
    .col-lg-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .quote {
        max-width: 973px;
    }
}