.mouseHover::before {
    transform: scaleX(0);
    transform-origin: bottom right;
}

.mouseHover:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.mouseHover::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    inset: 0 0 0 0;
    /*background: hsl(200 100% 80%);*/
    background: rgb(205, 242, 248);
    z-index: -1;
    transition: transform .3s ease;
}

.mouseHover {
    position: relative;
    /*font-size: 5rem;*/
}