.featuresContainer{

    max-width:58rem;
    margin:0 auto;
    padding: 20px 0 0 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.featuresSubtitleText{
    color:rgb(150, 169, 180);
    font-size: 1.125rem;
    max-width: 768px;
    line-height: 1.3;
    padding-bottom: 5rem;
}

.feature-container {
    display: grid;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-start;
    gap:2rem;
    max-width: 24rem;
}

.grid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
}
.grid-item h4 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.375;
    font-size: 1.5rem;
    font-weight: 700;
    color:rgb(217, 227, 234);
}

.grid-item p {
    color: rgb(155, 169, 180);
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.5;
}

.grid-line {
    width: calc(100% - 32px);
    left: calc(50% + 48px);
    top:32px;
    display: none;
    border-color: rgb(51, 54, 58);
    border-style: dashed;
    border-width: 0;
    border-top-width: 1px;
    height: 0.25rem;
    position: absolute;
}

.features-color-dot {
    width: 4rem;
    height: 4rem;
    font-weight: 900;
    font-size: 1.7rem;
    border-radius: 50%;
    line-height: 4rem;
    color:#3c4858
}


@media (min-width: 768px) {
    .feature-container{
        max-width: none;
        grid-template-columns: repeat(3, minmax(0,1fr));
    }
    .grid-line {
        display: block;
    }
}

@media (min-width: 768px) {
    .feature-container{
        gap: 4rem;
    }
}
