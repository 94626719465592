.container {
    margin: 0 auto;
    padding: 20px 10px 0 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.title{
    max-width: 58rem;
    padding-top: 1rem;
    padding-bottom: 5rem;
}

.small-quote {
    font-size: 1rem;
    font-style: italic;
    color: rgb(6, 141, 153);
}

.targetuser-row {
    margin-top: 1.5rem;
}

.textTitle {
    margin-bottom: 0.5rem;
}
.textBody {
    font-size: 1.125rem;
    color:rgb(155, 169, 180);
}

.text-purple {
    color:rgb(93, 93, 255);
}
.text-cyan {
    color:rgb(20, 184, 166);
}
.text-red {
    color:rgb(236, 72, 153);
}
.text-blue {
    color:rgb(59,130,246);
}
